import React, { useEffect, useState } from "react";
import "./gallary.css";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@mui/material";

const Gallary = () => {
  const { t } = useTranslation("global");
  const [value, setValue] = useState();
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/admin-dashboard/about`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setValue(data?.about?.gallery);
      });
  }, [value == null]);
  return (
    <div className="gallary ">
      <div
        className="container "
        style={{ paddingBottom: "130px", paddingTop: "100px" }}
      >
        <h2>{t("gallary.GALLERY")}</h2>
        <div className="row my-5 py-4">
          {value?value?.map((img) => {
            return (
              <div className="col-md-4">
                <div className="box_img">
                  <img src={img} alt=".." className="w-100" />
                </div>
              </div>
            );
          }): <><div className="col-md-4">
              <div className="box_img">
                <Skeleton style={{ height: "15rem" }} />
              </div>
            </div><div className="col-md-4">
                <div className="box_img">
                  <Skeleton style={{ height: "15rem" }} />
                </div>
              </div><div className="col-md-4">
                <div className="box_img">
                  <Skeleton style={{ height: "15rem" }} />
                </div>
              </div></>}
        </div>
      </div>
    </div>
  );
};

export default Gallary;
